import React from "react"
import Icon from "@mui/icons-material/Tv"
const Component = React.lazy(() => import("./Tv"))

export const routes = [
    {
        title: "TV",
        path: "/tv",
        Icon,
        Component,
    },
]
