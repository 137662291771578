import "./Sidebar.scss"

import classNames from "classnames"
import React from "react"
import { getMenuRoutes } from "../../helpers"

// Components
import { LinkListItem, LinkListItemSkin } from "../../components/LinkListItem"
import { CloseButton } from "../../components/Modal"

export interface Props {
    isOpen?: boolean
    onSidebarToggle?: () => void
}

export const Sidebar: React.FC<Props> = ({
    isOpen = false,
    onSidebarToggle = () => undefined,
}) => {
    return (
        <nav
            className={classNames("kjekk-sidebar", {
                "kjekk-sidebar--is-open": isOpen,
            })}
        >
            <div className="kjekk-sidebar__header">
                {" "}
                <h3 className="kjekk-sidebar__title">
                    Verktøy
                </h3>
                <CloseButton
                    className="kjekk-sidebar__button"
                    onClick={onSidebarToggle}
                />
            </div>
            <div className="kjekk-sidebar__content">
                {getMenuRoutes().map(({ path, title, Icon }) => (
                    <LinkListItem
                        path={path}
                        title={title}
                        Icon={Icon}
                        skin={LinkListItemSkin.Inverse}
                        key={path}
                    />
                ))}
            </div>
        </nav>
    )
}
