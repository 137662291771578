import React from "react"

export interface Props {
    className?: string
    children?: React.ReactNode
}

export const ModalFooter: React.FC<Props> = ({
    children,
    className = "kjekk-modal__footer",
    ...props
}) => {
    return (
        <div
            {...props}
            className={className}
        >
            {children}
        </div>
    )
}
