import "./Card.scss"

import React from "react"
import classNames from "classnames"

// Components
import { CardBody } from "./CardBody"
export interface Props {
    header?: string | React.ReactNode
    footer?: string | React.ReactNode
    afterFooter?: string | React.ReactNode
    style?: React.CSSProperties
    className?: string
    id?: string
    isBodyOmitted?: boolean
    onDoubleClick?: () => void
    children?: React.ReactNode
}

export const Card: React.FC<Props> = ({
    header = "",
    className = "",
    children,
    footer,
    style,
    id,
    onDoubleClick,
    isBodyOmitted,
    afterFooter = null,
}) => {
    return (
        <div
            className={classNames("kjekk-card", className)}
            style={style}
            id={id}
            onDoubleClick={onDoubleClick}
        >
            {header && (
                <h2 className="kjekk-card__header">
                    {header}
                </h2>
            )}
            {children && !isBodyOmitted && (
                <CardBody>
                    {children}
                </CardBody>
            )}
            {isBodyOmitted && children}
            {footer && (
                <div className="kjekk-card__footer">
                    {footer}
                </div>
            )}
            {afterFooter}
        </div>
    )
}
