import React from "react"
import Icon from "@mui/icons-material/RssFeed"
const Component = React.lazy(() => import("./Podcast"))

export const routes = [
    {
        title: "Podcast",
        path: "/podcast",
        Icon,
        Component,
    },
]
