import React from "react"
import Icon from "@mui/icons-material/GridOn"
const Component = React.lazy(() => import("./Dex"))

export const routes = [
    {
        title: "Dex",
        path: "/dex",
        hidden: true,
        Icon,
        Component,
    },
    {
        title: "Dex",
        path: "/dex/:filter",
        Component,
    },
    {
        title: "Dex",
        path: "/dex/:filter/:header",
        Component,
    },
]
