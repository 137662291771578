import React from "react"
import Icon from "@mui/icons-material/LibraryMusic"
const Component = React.lazy(() => import("./CoverSearch"))

export const routes = [
    {
        title: "Coversøk",
        path: "/cover",
        Icon,
        Component,
    },
]
