import React from "react"
import Icon from "@mui/icons-material/ColorLens"
const Component = React.lazy(() => import("./ColorContrastChecker"))

export const routes = [
    {
        title: "Kontrast",
        path: "/kontrast",
        Icon,
        Component,
    },
]
