import React from "react"
import Icon from "@mui/icons-material/Create"
const Component = React.lazy(() => import("./Wysiwyg"))

export const routes = [
    {
        title: "Wysiwyg",
        path: "/wysiwyg",
        Icon,
        Component,
    },
]
