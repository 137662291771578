import React from "react"
import Icon from "@mui/icons-material/AccessTime"
const Component = React.lazy(() => import("./AnalogClock"))

export const routes = [
    {
        title: "Analog klokke",
        path: "/analogklokke",
        Icon,
        Component,
    },
]

export default Component
