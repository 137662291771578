import React from "react"
import Icon from "@mui/icons-material/Code"
const Component = React.lazy(() => import("./Decoder"))

export const routes = [
    {
        title: "Dekoder",
        path: "/dekoder",
        Icon,
        Component,
    },
]
