import "./Subtitle.scss"

import React from "react"
import classNames from "classnames"

export interface Props {
    className?: string
    ElementType?: React.ElementType
    children?: React.ReactNode
}

export const Subtitle: React.FC<Props> = ({
    className,
    ElementType = "h2",
    children,
}) => {
    return (
        <ElementType className={classNames("kjekk-subtitle", className)}>
            {children}
        </ElementType>
    )
}
