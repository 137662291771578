import "./Card.scss"

import React from "react"
import classNames from "classnames"

export interface Props {
    children?: React.ReactNode
    className?: string
}

export const CardBody: React.FC<Props> = ({
    children,
    className,
}) => {
    return (
        <div className={classNames("kjekk-card__body", className)}>
            {children}
        </div>
    )
}
