import React from "react"
import Icon from "@mui/icons-material/Map"
const Component = React.lazy(() => import("./TimeZone"))

export const routes = [
    {
        title: "Tidssoner",
        path: "/tidssoner",
        Icon,
        Component,
    },
]
