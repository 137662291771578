import React from "react"
import { ErrorBoundary } from "../ErrorBoundary"
import { PageLoader } from "../PageLoader"

type Props = {
    errorMessage?: string
    children?: React.ReactNode
}

export const Suspender: React.FC<Props> = ({
    children,
    errorMessage = "Oops, this shouldn't happen",
}) => {
    return (
        <ErrorBoundary errorMessage={errorMessage}>
            <React.Suspense fallback={<PageLoader />}>
                {children}
            </React.Suspense>
        </ErrorBoundary>
    )
}
