import React from "react"
import Icon from "@mui/icons-material/Watch"
const Component = React.lazy(() => import("./DigitalClock"))

export const routes = [
    {
        title: "Digital klokke",
        path: "/digitalklokke",
        Icon,
        Component,
    },
]

export default Component
