import React from "react"
import Icon from "@mui/icons-material/Image"
const Component = React.lazy(() => import("./Fusker"))

export const routes = [
    {
        title: "Fusker",
        path: "/fusker",
        Icon,
        Component,
    },
]
