import axios from "axios"
import useSWR from "swr"
import { serialize } from "../helpers"

export enum ReqFormat {
    HTML,
    JSON,
}

type FetchOptions = {
    method?: string | undefined;
    headers?: Record<string, string> | undefined;
    credentials?: "include" | "omit" | undefined;
    body?: Document | XMLHttpRequestBodyInit | null | undefined;
}

export const fetcher = (url: string, options: FetchOptions) => axios.get(url, options).then(res => res.data)

export interface Request {
    url: string;
    method?: string;
    data?: Record<string, unknown>;
    format?: ReqFormat;
    proxy?: boolean;
}

export const useFetch = <T>(req: Request) => {
    let fetchUrl = req.url
    let fetchOptions: FetchOptions = {}

    if (req.proxy) {
        fetchUrl = `https://of-cors.herokuapp.com/${fetchUrl}`
    }

    if (!req.method || req.method === "GET") {
        fetchUrl = req.data ? `${fetchUrl}?${serialize(req.data)}` : fetchUrl
    } else {
        fetchOptions = {
            body: JSON.stringify(req.data),
            headers: {
                "content-type": "application/json",
                "referrer": "no-referrer",
            },
            method: req.method,
        }
    }

    const { data } = useSWR(fetchUrl, () => fetcher(fetchUrl, fetchOptions), { suspense: true })

    return data as T
}
