import React from "react"

// Components
import { PageTitle } from "../PageTitle"
import { Card } from "../Card"
import { Collapse } from "../Collapse"

type State = {
    hasError: boolean
    error: Record<string, unknown> | null
    isErrorVisible: boolean
}

type Props = {
    fallback?: JSX.Element
    errorMessage?: string
    children?: React.ReactNode
}

// Error boundaries currently have to be classes.
export class ErrorBoundary extends React.Component<Props, State> {
    state = {
        hasError: false,
        error: { message: "" },
        isErrorVisible: false,
    }

    static getDerivedStateFromError(error: Record<string, unknown>) {
        return {
            hasError: true,
            error,
        }
    }

    render() {
        const { errorMessage, fallback, children } = this.props
        const { hasError, error, isErrorVisible } = this.state

        if (hasError) {
            if (fallback) {
                return fallback
            }

            return (
                <>
                    <PageTitle onClick={() => this.setState({ isErrorVisible: !isErrorVisible })}>
                        {errorMessage}
                    </PageTitle>
                    <Collapse isOpen={isErrorVisible}>
                        <Card header={error?.message}>
                            <pre>{JSON.stringify(error, null, 2)}</pre>
                        </Card>
                    </Collapse>
                </>
            )
        }

        return children
    }
}
