import "./Modal.scss"

import React, { useEffect, useState } from "react"
import ReactModal from "react-modal"
import { ModalBody } from "./ModalBody"
import { ModalFooter } from "./ModalFooter"
import { ModalHeader } from "./ModalHeader"
import { ModalTitle } from "./ModalTitle"

export interface Props {
    onClose: () => void
    isOpen?: boolean
    title: string
    closeButton?: boolean
    children?: React.ReactNode
    actions?: React.ReactNode
}

export const Modal: React.FC<Props> = ({
    children,
    onClose,
    title,
    closeButton = true,
    isOpen = true,
    actions,
}) => {
    const [derivedIsOpen, setDerivedIsOpen] = useState(isOpen)

    useEffect(() => {
        const timeoutId = window.setTimeout(() => setDerivedIsOpen(isOpen), isOpen ? 0 : 150)

        return () => clearTimeout(timeoutId)
    }, [isOpen])

    return (
        <ReactModal
            isOpen={derivedIsOpen}
            onRequestClose={onClose}
            aria-labelledby="kjekk-modal__title"
            bodyOpenClassName="kjekk-modal-body--open"
            portalClassName="kjekk-modal"
            overlayClassName={{
                base: "kjekk-modal__backdrop",
                afterOpen: "kjekk-modal__backdrop--after-open",
                beforeClose: "kjekk-modal__backdrop--before-close",
            }}
            className={{
                base: "kjekk-modal__dialog",
                afterOpen: "kjekk-modal__dialog--after-open",
                beforeClose: "kjekk-modal__dialog--before-close",
            }}
            closeTimeoutMS={600}
            onAfterClose={onClose}
        >
            <div className="kjekk-modal__content">
                {(closeButton || title) && (
                    <ModalHeader
                        closeButton={closeButton}
                        onClose={onClose}
                    >
                        {title && (
                            <ModalTitle id="modal-title">
                                {title}
                            </ModalTitle>
                        )}
                    </ModalHeader>
                )}
                <ModalBody>
                    {children}
                </ModalBody>
                {actions && (
                    <ModalFooter>
                        {actions}
                    </ModalFooter>
                )}
            </div>
        </ReactModal>
    )
}
