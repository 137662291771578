import React from "react"
import Icon from "@mui/icons-material/CloudDownload"
const Component = React.lazy(() => import("./MyPirateBay"))

export const routes = [
    {
        title: "MyPirateBay",
        path: "/mypiratebay",
        hidden: true,
        Icon,
        Component,
    },
]
