import React from "react"
import Icon from "@mui/icons-material/Bookmarks"
const Component = React.lazy(() => import("./Bookmarklets"))

export const routes = [
    {
        title: "Bookmarklets",
        path: "/bookmarklets",
        Icon,
        Component,
    },
]
