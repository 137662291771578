import React from "react"
import ReactDOM from "react-dom/client"
import ReactModal from "react-modal"
import { Framework } from "./app/Framework"

ReactModal.setAppElement("#root")

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLDivElement)
root.render(
    <React.StrictMode>
        <Framework />
    </React.StrictMode>,
)
