import "./LinkListItem.scss"

import React from "react"
import { Link } from "react-router-dom"
import { useMatch } from "react-router"
import classNames from "classnames"

// Components
import { Icon } from "../../components/Icon"

export enum LinkListItemSkin {
    Default = "DEFAULT",
    Inverse = "INVERSE",
}

export interface Props {
    path: string
    Icon: React.ComponentType
    title: string
    className?: string
    skin?: LinkListItemSkin
}

export const LinkListItem: React.FC<Props> = ({
    path,
    Icon: LinkListItemIcon,
    title,
    className,
    skin = LinkListItemSkin.Default,
}) => {
    return (
        <Link
            to={path}
            key={path}
            className={classNames("kjekk-link-list-item", className, {
                "kjekk-link-list-item--inverse": skin === LinkListItemSkin.Inverse,
                "kjekk-link-list-item--selected": useMatch({ path }),
            })}
        >
            <Icon
                className="kjekk-link-list-item__icon"
                name={LinkListItemIcon}
            />
            {" "}
            {title}
        </Link>
    )
}
