import "./PageLoader.scss"

import React, { useEffect, useState } from "react"
import classNames from "classnames"

// Components
import { Spinner } from "../Spinner"

export const PageLoader: React.FC = () => {
    const [progressIsVisible, setProgressIsVisible] = useState(false)

    useEffect(() => {
        const timeoutId = window.setTimeout(() => {
            setProgressIsVisible(true)
        }, 750)

        return () => clearTimeout(timeoutId)
    }, [])

    const elementClassNames = classNames(
        "kjekk-page-loader",
        {
            "kjekk-page-loader--show": progressIsVisible,
        },
    )

    return (
        <div className={elementClassNames}>
            <Spinner />
        </div>
    )
}
