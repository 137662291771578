export const serialize = (obj: Record<string, unknown>, prefix = ""): string => {
    const str = []

    for (const p in obj) {
        const k = prefix ? prefix + "[" + p + "]" : p
        const v: unknown = obj[p]
        str.push((v !== null && typeof v === "object") ?
            serialize(v as Record<string, unknown>, k) :
            encodeURIComponent(k) + "=" + encodeURIComponent(`${v}`))
    }
    return str.join("&")
}
