import "./Framework.scss"

import classNames from "classnames"
import React, { useState } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { routes } from "../../features"

// Components
import { Navbar } from "../Navbar"
import { PageTitleUpdater } from "../PageTitleUpdater"
import { Sidebar } from "../Sidebar"
import { Suspender } from "../../components/Suspender"

export const Framework: React.FC = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const year = new Date().getFullYear()

    return (
        <BrowserRouter>
            <div className="kjekk-framework">
                <PageTitleUpdater />
                <div
                    className={classNames("kjekk-framework__content", {
                        "kjekk-framework__content--is-sidebar-open": isSidebarOpen,
                    })}
                >
                    <Navbar onSidebarToggle={() => setIsSidebarOpen(!isSidebarOpen)} />
                    <main
                        className={classNames("kjekk-framework__main container", {
                            "kjekk-framework__main--is-sidebar-open": isSidebarOpen,
                        })}
                    >
                        <Suspender errorMessage="Oops...">
                            <Routes>
                                {routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={<route.Component />}
                                    />
                                ))}
                            </Routes>
                        </Suspender>
                    </main>
                    <footer className="kjekk-framework__footer">
                        <p className="kjekk-framework__footer-text">
                            Copyright © Kjekt {year}
                        </p>
                    </footer>
                </div>
                <Sidebar
                    isOpen={isSidebarOpen}
                    onSidebarToggle={() => setIsSidebarOpen(!isSidebarOpen)}
                />
            </div>
        </BrowserRouter>
    )
}
