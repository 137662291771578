import { routes } from "../features"

export const getMenuRoutes = () => {
    return routes
        .filter(route => {
            const routeMatch = route.path.match(/\//g) || []
            return !route.hidden && route.path !== "/" && routeMatch.length === 1
        })
        .sort((a, b) => a.title.localeCompare(b.title))
}
