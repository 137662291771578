import "./PageTitle.scss"

import React from "react"

export interface Props {
    children?: React.ReactNode
    // All other props
    [x: string]: any
}

export const PageTitle: React.FC<Props> = ({
    children,
    ...rest
}) => {
    return (
        <h1
            className="kjekk-page-title"
            {...rest}
        >
            {children}
        </h1>
    )
}
