import React from "react"
import Icon from "@mui/icons-material/AccessTime"
const Component = React.lazy(() => import("./OpeningHours"))

export const routes = [
    {
        title: "Åpningstider",
        path: "/apningstider",
        hidden: true,
        Icon,
        Component,
    },
]
