import "./CloseButton.scss"

import classNames from "classnames"
import React from "react"

import CloseIcon from "@mui/icons-material/Close"

// Components
import { Icon } from "../Icon"

export interface Props {
    className?: string
    onClick?: (event: React.MouseEvent) => void
}

export const CloseButton: React.FC<Props> = ({
    className,
    onClick = () => undefined,
}) => {
    return (
        <button
            type="button"
            className={classNames("kjekk-close-button", className)}
            onClick={onClick}
            aria-label="Lukk"
        >
            <Icon name={CloseIcon} />
        </button>
    )
}
