import React from "react"
import Icon from "@mui/icons-material/Crop"
const Component = React.lazy(() => import("./SvgCropper"))

export const routes = [
    {
        title: "SVG Cropper",
        path: "/svg-cropper",
        Icon,
        Component,
    },
]
