import { Route } from "../types/Route"

import { routes as AnalogClockRoutes } from "./AnalogClock"
import { routes as AreaCodesRoutes } from "./AreaCodes"
import { routes as BookmarkletsRoutes } from "./Bookmarklets"
import { routes as CalendarRoutes } from "./Calendar"
import { routes as ClockRoutes } from "./DigitalClock"
import { routes as CodeRoutes } from "./Code"
import { routes as ColorContrastCheckerRoutes } from "./ColorContrastChecker"
import { routes as ComponentsRoutes } from "./Components"
import { routes as CoverSearchRoutes } from "./CoverSearch"
import { routes as DateConverterRoutes } from "./DateConverter"
import { routes as DecoderRoutes } from "./Decoder"
import { routes as DexRoutes } from "./Dex"
import { routes as DiceRoutes } from "./Dice"
import { routes as EpisodesRoutes } from "./Episodes"
import { routes as FeedRoutes } from "./Feed"
import { routes as FuskerRoutes } from "./Fusker"
import { routes as HomeRoutes } from "./Home"
import { routes as LinesRoutes } from "./Lines"
import { routes as MyPirateBayRoutes } from "./MyPirateBay"
import { routes as OnOfflineRoutes } from "./OnOffline"
import { routes as OpeningHoursRoutes } from "./OpeningHours"
import { routes as PodcastRoutes } from "./Podcast"
import { routes as SvgCropperRoutes } from "./SvgCropper"
import { routes as TimeZoneRoutes } from "./TimeZone"
import { routes as TravelRoutes } from "./Travel"
import { routes as TvRoutes } from "./Tv"
import { routes as WysiwygRoutes } from "./Wysiwyg"

const routesDeconstruct = [
    ...AnalogClockRoutes,
    ...AreaCodesRoutes,
    ...BookmarkletsRoutes,
    ...CalendarRoutes,
    ...ClockRoutes,
    ...CodeRoutes,
    ...ColorContrastCheckerRoutes,
    ...ComponentsRoutes,
    ...CoverSearchRoutes,
    ...DateConverterRoutes,
    ...DecoderRoutes,
    ...DexRoutes,
    ...DiceRoutes,
    ...EpisodesRoutes,
    ...FeedRoutes,
    ...FuskerRoutes,
    ...HomeRoutes,
    ...LinesRoutes,
    ...MyPirateBayRoutes,
    ...OnOfflineRoutes,
    ...OpeningHoursRoutes,
    ...PodcastRoutes,
    ...SvgCropperRoutes,
    ...TimeZoneRoutes,
    ...TravelRoutes,
    ...TvRoutes,
    ...WysiwygRoutes,
]

export const routes = routesDeconstruct as Route[]
