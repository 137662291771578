import React from "react"
import Icon from "@mui/icons-material/PlayCircleFilled"
const Component = React.lazy(() => import("./Episodes"))

export const routes = [
    {
        title: "Episoder",
        path: "/episoder",
        Icon,
        Component,
    },
]
