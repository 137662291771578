import React from "react"
import Icon from "@mui/icons-material/CalendarToday"
const Component = React.lazy(() => import("./Calendar"))

export const routes = [
    {
        title: "Kalender",
        path: "/kalender",
        Icon,
        Component,
    },
]
