import html2canvas from "html2canvas"

export const captureElement = (el: HTMLElement | null, fileName = "Dex") => {
    if (!el) {
        return
    }

    html2canvas(el).then((canvas: HTMLCanvasElement) => {
        const link = document.createElement("a")
        link.download = `${fileName}.png`
        link.href = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
        link.click()
    })
}
