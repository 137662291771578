import "./Navbar.scss"

import React from "react"
import { useLocation } from "react-router"
import { Link, Route, Routes } from "react-router-dom"
import { routes } from "../../features"

import MenuIcon from "@mui/icons-material/MenuOpen"

// Components
import { Icon } from "../../components/Icon"

export interface Props {
    onSidebarToggle?: () => void
}

export const Navbar: React.FC<Props> = ({
    onSidebarToggle = () => undefined,
}) => {
    const { pathname } = useLocation()

    return (
        <nav className="kjekk-navbar">
            <h1 className="kjekk-navbar__title">
                {pathname !== "/" && (
                    <Link
                        className="kjekk-navbar__home-link"
                        to="/"
                    >
                        Kjekt &rsaquo;
                    </Link>
                )}
                {" "}
                <Routes>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            element={(
                                <>
                                    {route.title}
                                </>
                            )}
                        />
                    ))}
                </Routes>
            </h1>
            <button
                className="kjekk-navbar__button"
                onClick={onSidebarToggle}
            >
                <Icon name={MenuIcon} />
            </button>
        </nav>
    )
}
