import React from "react"
import Icon from "@mui/icons-material/AccessTime"
const Component = React.lazy(() => import("./Home"))

export const routes = [
    {
        title: "Kjekt",
        path: "/",
        Icon,
        Component,
    },
]
