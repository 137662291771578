import React from "react"
import Icon from "@mui/icons-material/Casino"
const Component = React.lazy(() => import("./Dice"))

export const routes = [
    {
        title: "Terninger",
        path: "/terninger",
        Icon,
        Component,
    },
]

export default Component
