import "./Spinner.scss"

import React from "react"

export const Spinner: React.FC<Record<string, never>> = () => {
    return (
        <div
            className="kjekk-spinner"
            role="status"
        >
            <span className="visually-hidden">
                Laster...
            </span>
        </div>
    )
}
