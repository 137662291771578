import React from "react"

import { CloseButton } from "./CloseButton"
export interface Props {
    className?: string
    closeButton?: boolean
    onClose?: (event: React.MouseEvent) => void
    children?: React.ReactNode
}

export const ModalHeader: React.FC<Props> = ({
    children,
    closeButton,
    className = "kjekk-modal__header",
    onClose = () => undefined,
    ...props
}) => {
    return (
        <div
            {...props}
            className={className}
        >
            {children}
            {closeButton && onClose && (
                <CloseButton
                    className="kjekk-modal__close-button"
                    onClick={onClose}
                />
            )}
        </div>
    )
}
