import "./Icon.scss"

import React from "react"
import classNames from "classnames"

export interface Props {
    name: React.ComponentType
    className?: string
}

export const Icon: React.FC<Props> = ({
    name,
    className,
}) => {
    const IconElement = name

    return (
        <div className={classNames("kjekk-icon", className)}>
            <IconElement />
        </div>
    )
}
