import React from "react"
import Icon from "@mui/icons-material/Sort"
const Component = React.lazy(() => import("./Code"))

export const routes = [
    {
        title: "Kode",
        path: "/kode",
        hidden: true,
        Icon,
        Component,
    },
]
