import React from "react"
import Icon from "@mui/icons-material/Today"
const Component = React.lazy(() => import("./DateConverter"))

export const routes = [
    {
        title: "Datokonvertering",
        path: "/dato",
        Icon,
        Component,
    },
]
