import React from "react"
import Icon from "@mui/icons-material/LocalPhone"
const Component = React.lazy(() => import("./AreaCodes"))

export const routes = [
    {
        title: "Retningsnummer",
        path: "/retningsnummer",
        Icon,
        Component,
    },
]
