import React from "react"
import { useLocation } from "react-router"
import { getMenuRoutes } from "../../helpers"

export const PageTitleUpdater: React.FC = () => {
    const { pathname } = useLocation()
    const current = getMenuRoutes().find(({ path }) => path === pathname)
    document.title = `${current ? `${current.title} «` : ""} Kjekt`
    return null
}
