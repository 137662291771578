import React from "react"
import Icon from "@mui/icons-material/Directions"
const Component = React.lazy(() => import("./Travel"))

export const routes = [
    {
        title: "Reise",
        path: "/reise",
        Icon,
        Component,
    },
]
