import React from "react"
import Icon from "@mui/icons-material/Newspaper"
const Component = React.lazy(() => import("./Feed"))

export const routes = [
    {
        title: "Strøm",
        path: "/strom",
        Icon,
        Component,
    },
]
