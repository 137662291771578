import React from "react"

export interface Props {
    className?: string
    isOpen?: boolean
    transition?: string
    onRest?: () => void
    children?: React.ReactNode
}
export interface State {
    height: string
    overflow: string
    transition?: string
    visibility: string
    willChange: string
}

export class Collapse extends React.PureComponent<Props, State> {
    public static defaultProps = {
        className: "react-css-collapse-transition",
        transition: "height 0.5s ease",
        isOpen: false,
    }

    private content: React.RefObject<HTMLDivElement>

    constructor(props: Props) {
        super(props)
        this.onTransitionEnd = this.onTransitionEnd.bind(this)
        this.setExpanded = this.setExpanded.bind(this)
        this.setCollapsed = this.setCollapsed.bind(this)
        this.content = React.createRef()

        this.state = {
            height: "0",
            overflow: "hidden",
            transition: props.transition,
            visibility: "hidden",
            willChange: "height",
        }
    }

    public componentDidMount() {
        if (this.content && this.props.isOpen) {
            this.setExpanded()
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (!this.content) {
            return
        }

        // If the transition is changed lets update it
        if (prevProps.transition !== this.props.transition) {
            this.setState({ transition: this.props.transition })
        }

        // expand
        if (this.props.isOpen && !prevProps.isOpen) {
            // have the element transition to the height of its inner content
            this.setState({
                height: `${this.getHeight()}px`,
                visibility: "visible",
            })
        }

        // collapse
        if (!this.props.isOpen && prevProps.isOpen) {
            // explicitly set the element's height to its current pixel height, so we
            // aren't transitioning out of 'auto'
            this.setState({ height: `${this.getHeight()}px` })
            window.requestAnimationFrame(() => {
                // "pausing" the JavaScript execution to let the rendering threads catch up
                // http://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful
                window.setTimeout(() => {
                    this.setState({
                        height: "0",
                        overflow: "hidden",
                    })
                })
            })
        }
    }

    public onTransitionEnd(e: React.TransitionEvent<HTMLDivElement>) {
        const { onRest, isOpen } = this.props

        if (e.target === this.content.current && e.propertyName === "height") {
            if (isOpen) {
                this.setExpanded()
            } else {
                this.setCollapsed()
            }
            if (onRest) {
                onRest()
            }
        }
    }

    public getHeight() {
        return this.content.current ? this.content.current.scrollHeight : 0
    }

    public setCollapsed() {
        this.setState({
            visibility: "hidden",
        })
    }

    public setExpanded() {
        this.setState({
            height: "auto",
            overflow: "visible",
            visibility: "visible",
        })
    }

    public render() {
        const {
            className,
            children,
            ...attrs
        } = this.props

        delete attrs.isOpen
        delete attrs.onRest

        return (
            <div
                ref={this.content}
                style={this.state as React.CSSProperties}
                className={className}
                onTransitionEnd={this.onTransitionEnd}
                {...attrs}
            >
                {children}
            </div>
        )
    }
}
